import { Calculator, CheckHandwrite, Eye, MagnifyingGlassLeft } from '@pretto/picto'
import Layout from '@pretto/website/src/components/Layout'
import SEO from '@pretto/website/src/components/SEO'
import Schema from '@pretto/website/src/components/Schema'
import { Dispatcher } from '@pretto/website/src/postTypes/page/controllers/dispatcher/dispatcher'

import { graphql } from 'gatsby'
import PropTypes from 'prop-types'

const __components__ = { Calculator, CheckHandwrite, Eye, MagnifyingGlassLeft }

const Page = props => (
  <Layout topBannerSettings={props.data.wp.acfOptionsHomeSettings.topBanner} template={props.data.wpPage.template}>
    <Dispatcher {...props} pictoComponents={__components__} />

    <Schema
      data={{
        author: props.data.wpPage.author.node,
        date: props.data.wpPage.date,
        description: props.data.wpPage.seo.description,
        image: props.data.wpPage.postTypeMeta.highlightedImage,
        modified: props.data.wpPage.modified,
        title: props.data.wpPage.title,
      }}
    />

    <SEO
      {...props.data.wpPage.seo}
      date={props.data.wpPage.date}
      image={props.data.wpPage.postTypeMeta.highlightedImage}
      modified={props.data.wpPage.modified}
      template={props.data.wpPage.template}
    />
  </Layout>
)

Page.propTypes = {
  data: PropTypes.shape({
    wp: PropTypes.object,
    wpMenu: PropTypes.object,
    wpPage: PropTypes.object.isRequired,
  }).isRequired,
}

export const query = graphql`
  query Page__Calculator_CheckHandwrite_Eye_MagnifyingGlassLeft(
    $id: String!
    $parentId: ID
    $secondaryMenuId: Int
    $shouldIncludeArticlesCategories: Boolean!
    $shouldIncludeSecondaryMenu: Boolean!
    $templateBlocsArticlesCategories: [String]
    $templateBlocsTags: [String!]!
  ) {
    articles: allWpPost(
      filter: { categories: { nodes: { elemMatch: { slug: { in: $templateBlocsArticlesCategories } } } } }
    ) @include(if: $shouldIncludeArticlesCategories) {
      nodes {
        uri
        title
        modified
        date
        postTypeMeta {
          highlightedimage
        }
        categories {
          nodes {
            slug
          }
        }
      }
    }
    articlesCategory: allWpCategory(filter: { slug: { in: $templateBlocsArticlesCategories } })
      @include(if: $shouldIncludeArticlesCategories) {
      nodes {
        name
        slug
      }
    }
    allTemplateBlocsTag: allWpTag(filter: { name: { in: $templateBlocsTags } }) {
      nodes {
        name
        posts {
          nodes {
            categories {
              nodes {
                databaseId
              }
            }
            databaseId
            date
            modified
            title
            uri
          }
        }
      }
    }
    rates(id: { ne: "dummy" }) {
      data {
        marketRatesHistory: market_rates_history {
          averageRate: average_rate
          date
          duration
          ordinaryRate: ordinary_rate
          sigma
          veryGoodRate: very_good_rate
        }
        marketRatesRegionHistory: market_rates_region_history {
          averageRate: average_rate
          date
          duration
          ordinaryRate: ordinary_rate
          region
          sigma
          veryGoodRate: very_good_rate
        }
        marketRatesLatest: market_rates_latest {
          averageRate: average_rate
          date
          duration
          ordinaryRate: ordinary_rate
          sigma
          veryGoodRate: very_good_rate
        }
        marketRatesProfileLatest: market_rates_profile_latest {
          averageRate: average_rate
          date
          duration
          mortgagors: nb_mortgagors
          ordinaryRate: ordinary_rate
          salaryMax: salary_max
          salaryMin: salary_min
          sigma
          trend
          veryGoodRate: very_good_rate
        }
        marketRatesProfileRegionLatest: market_rates_profile_region_latest {
          averageRate: average_rate
          date
          duration
          maxOrdinaryRate: max_ordinary_rate_display
          maxVeryGoodRate: max_very_good_rate_display
          minOrdinaryRate: min_ordinary_rate_display
          minVeryGoodRate: min_very_good_rate_display
          mortgagors: nb_mortgagors
          ordinaryRate: ordinary_rate
          region
          salaryMax: salary_max
          salaryMin: salary_min
          sigma
          trend
          veryGoodRate: very_good_rate
        }
        marketRatesRegionLatest: market_rates_region_latest {
          averageRate: average_rate
          date
          duration
          ordinaryRate: ordinary_rate
          region
          sigma
          veryGoodRate: very_good_rate
        }
      }
    }
    secondaryMenu: allWpMenu(filter: { databaseId: { eq: $secondaryMenuId } })
      @include(if: $shouldIncludeSecondaryMenu) {
      nodes {
        menuItems {
          nodes {
            uri
            label
          }
        }
      }
    }
    wp {
      acfOptionsHomeSettings {
        topBanner: homeSettings {
          content: homeSettingsBannerContent
          isDisplayed: homeSettingsBannerIsDisplayed
          location: homeSettingsBannerLocation
        }
      }
      acfOptionsRatesSettings {
        ratesSettings {
          anchor: monthlyUpdateAnchor
          news: monthlyUpdateNews {
            ... on WpPost {
              date
              excerpt
              uri
            }
          }
          title: monthlyUpdateTitle
        }
      }
    }
    wpMenu(parentPageId: { eq: $parentId, ne: null }) {
      menuItems {
        nodes {
          label
          url: path
        }
      }
    }
    wpPage(id: { eq: $id }) {
      author {
        node {
          ...authorSignature
        }
      }
      comments {
        nodes {
          ...comment
        }
      }
      content
      databaseId
      date
      excerpt
      modified
      postTypeMeta {
        highlightedImage: highlightedimage
        isSidebarMenuActive: issidebarmenuactive
      }
      seo {
        canonical
        description: metaDesc
        noindex: metaRobotsNoindex
        title
      }
      template {
        __typename
        ... on WpTemplate_BaseDocumentaire {
          ...BaseDocumentaireTemplate
        }
        ... on WpTemplate_Blocs {
          ...BlocsTemplate
        }
        ... on WpTemplate_EnSavoirPlusdprci {
          ...EnSavoirPlusTemplate
        }
        ... on WpTemplate_Home {
          ...HomeBlocs
        }
      }
      title
    }
  }
`

export default Page
